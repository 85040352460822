// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import {
    MonitorOverview, OverviewStatuses, SectionMonitorInfo,
    SectionMonitorStatus, SectionTrendData, SectionMonitorRuleInstanceDataSet,
    Issue, RuleInstanceLastCheckedData, RuleInstanceProblemTime, SectionStatus,
    MonitorSectionSettings, DecimatedLog,
    MatchingIssuesFilter, MatchingIssuesResult, RuleSuppressionInfo,
} from '@/_models';
import { IndexedLogMetadata } from '@/_models/live-kpi';

export class SaveSectionIdInStoreAction implements Action {
    public type = '[Monitor] Save section id in store action';
    constructor(public readonly sectionId: number) { }
}

export class LoadOrderOverviewsAction implements Action {
    public type = '[Monitor] Load orderOverviews action';

    constructor(public readonly orderOverviews: MonitorOverview[]) { }
}

export class UpdateOrderOverviewsAction implements Action {
    public type = '[Monitor] Update orderOverviews action';

    constructor(public readonly sectionId: number, public readonly newState: SectionStatus) { }
}

export class FetchOrderOverviewsAction implements Action {
    public type = '[Monitor] Fetch orderOverviews action';
}

export class FetchOkOrderOverviewsAction implements Action {
    public type = '[Monitor] Fetch OK orderOverviews action';
}

export class FetchFailedOrderOverviewsAction implements Action {
    public type = '[Monitor] Fetch Cancel orderOverviews action';
    constructor(public readonly error: Error | string | any) { }
}

export class StartStopOrderOverviewsAction implements Action {
    public type = '[Monitor] Start/Stop orderOverviews action';

    constructor(public readonly sectionId: number,
        public readonly newState: SectionStatus,
        public readonly oldState: SectionStatus) { }
}

export class StartStopOkOrderOverviewsAction implements Action {
    public type = '[Monitor] Start/Stop OK orderOverviews action';
}

export class StartStopFailedOrderOverviewsAction implements Action {
    public type = '[Monitor] Start/Stop Failed orderOverviews action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadInStateOverviewStatusesAction implements Action {
    public type = '[Monitor] Load overview statuses action';

    constructor(public readonly statuses: OverviewStatuses[]) { }
}

//#region async Fetch OverviewStatuses

export class FetchOverviewStatusesAction implements Action {
    public type = '[Monitor] Fetch OverviewStatuses action';

    constructor(public readonly sectionIds: number[]) { }
}
export class FetchOkOverviewStatusesAction implements Action {
    public type = '[Monitor] Fetch OK OverviewStatuses action';
}
export class FetchFailedOverviewStatusesAction implements Action {
    public type = '[Monitor] Fetch Cancel OverviewStatuses action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch OverviewStatuses

export class LoadInStateSectionMonitorInfoAction implements Action {
    public type = '[Monitor] Load Section Monitor Info action';

    constructor(public readonly data: SectionMonitorInfo) { }
}

//#region async Fetch SectionMonitorInfo

export class FetchSectionMonitorInfoAction implements Action {
    public type = '[Monitor] Fetch SectionMonitorInfo action';
    constructor(public readonly sectionId: number) { }
}
export class FetchOkSectionMonitorInfoAction implements Action {
    public type = '[Monitor] Fetch OK SectionMonitorInfo action';
}
export class FetchFailedSectionMonitorInfoAction implements Action {
    public type = '[Monitor] Fetch Cancel SectionMonitorInfo action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch SectionMonitorInfo

export class LoadInStateSectionMonitorStatusAction implements Action {
    public type = '[Monitor] Load Section Monitor Status action';

    constructor(public readonly data: SectionMonitorStatus) { }
}

//#region async Fetch SectionMonitorStatus

export class FetchSectionMonitorStatusAction implements Action {
    public type = '[Monitor] Fetch SectionMonitorStatus action';
    constructor(public readonly sectionId: number) { }
}
export class FetchOkSectionMonitorStatusAction implements Action {
    public type = '[Monitor] Fetch OK SectionMonitorStatus action';
}
export class FetchFailedSectionMonitorStatusAction implements Action {
    public type = '[Monitor] Fetch Cancel SectionMonitorStatus action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch SectionMonitorStatus

export class LoadInStateSectionTrendDataAction implements Action {
    public type = '[Monitor] Load Section Trend Data action';

    constructor(public readonly data: SectionTrendData) { }
}

//#region async Fetch SectionTrendData

export class FetchSectionTrendDataAction implements Action {
    public type = '[Monitor] Fetch SectionTrendData action';
    constructor(
        public readonly sectionId: number,
        public readonly targetId: number,
        public readonly earliestTime: Date | null,
        public readonly latestTime: Date | null,
        public readonly categoryIds: string[],
    ) { }
}
export class FetchOkSectionTrendDataAction implements Action {
    public type = '[Monitor] Fetch OK SectionTrendData action';
}
export class FetchFailedSectionTrendDataAction implements Action {
    public type = '[Monitor] Fetch Cancel SectionTrendData action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch SectionTrendData

export class LoadInStateSectionInstanceDataAction implements Action {
    public type = '[Monitor] Load Section Instance Data action';

    constructor(public readonly data: SectionMonitorRuleInstanceDataSet) { }
}

//#region async Fetch SectionInstanceData

export class FetchSectionInstanceDataAction implements Action {
    public type = '[Monitor] Fetch SectionInstanceData action';
    constructor(public readonly sectionId: number, public readonly targetId: number,
        public readonly categoryId: string,
        public readonly categoryService?: string,
        public readonly categoryType?: string,
        public readonly categorySubject?: string,
        public readonly subjectType?: string,
        public readonly subjectId?: string,
        public readonly monitorId?: string) { }
}
export class FetchOkSectionInstanceDataAction implements Action {
    public type = '[Monitor] Fetch OK SectionInstanceData action';
}
export class FetchFailedSectionInstanceDataAction implements Action {
    public type = '[Monitor] Fetch Cancel SectionInstanceData action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch SectionInstanceData

export class LoadInStateSectionIssuesAction implements Action {
    public type = '[Monitor] Load Section Issues action';

    constructor(public readonly issues: Issue[]) { }
}

export class ClearInStateSectionIssuesAction implements Action {
    public type = '[Monitor] Clear Section Issues action';
}

// #region async Fetch SectionIssues

export class FetchSectionIssuesAction implements Action {
    public type = '[Monitor] Fetch SectionIssues action';
    constructor(public readonly ruleInstanceIds: number[]) { }
}
export class FetchOkSectionIssueAction implements Action {
    public type = '[Monitor] Fetch OK SectionIssues action';
}
export class FetchFailedSectionIssueAction implements Action {
    public type = '[Monitor] Fetch Cancel SectionIssues action';
    constructor(public readonly error: Error | string | any) { }
}

// #endregion async Fetch SectionIssues

export class UpdateInStateRuleInstanceStateAction implements Action {
    public type = '[Monitor] Update in State RuleInstanceState action';
    constructor(public readonly ids: number[], public readonly isEnabled: boolean) { }
}

//#region async Update RuleInstanceState

export class UpdateRuleInstanceStateAction implements Action {
    public type = '[Monitor] Update RuleInstanceState action';
    constructor(public readonly sectionId: number, public readonly ids: number[], public readonly isEnabled: boolean) { }
}
export class UpdateOkRuleInstanceStateAction implements Action {
    public type = '[Monitor] Update OK RuleInstanceState action';
}
export class UpdateFailedRuleInstanceStateAction implements Action {
    public type = '[Monitor] Update Cancel RuleInstanceState action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateRuleInstanceAlertStateAction implements Action {
    public type = '[Monitor] Update RuleInstanceAlertState action';
    constructor(public readonly sectionId: number, public readonly ids: number[], public readonly isEnabled: boolean) { }
}
export class UpdateOkRuleInstanceAlertStateAction implements Action {
    public type = '[Monitor] Update OK RuleInstanceAlertState action';
}
export class UpdateFailedRuleInstanceAlertStateAction implements Action {
    public type = '[Monitor] Update Cancel RuleInstanceAlertState action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateInStateRuleInstanceAlertStateAction implements Action {
    public type = '[Monitor] Update in State RuleInstanceAlertState action';
    constructor(public readonly ids: number[], public readonly isEnabled: boolean) { }
}

export class FetchMonitorStatusAfterDelay implements Action {
    public type = '[Monitor] Fetch MonitorStatus after delay action';
    constructor(public readonly sectionId: number) { }
}

export class LoadInStateRuleInstanceProblemTimeAction implements Action {
    public type = '[Monitor] Load RuleInstance ProblemTime action';

    constructor(public readonly ruleInstancesProblemTime: RuleInstanceProblemTime[]) { }
}

// #region async Fetch RuleInstance ProblemTime

export class FetchRuleInstanceProblemTimeAction implements Action {
    public type = '[Monitor] Fetch RuleInstance Problem Time action';
    constructor(public readonly ruleInstanceIds: number[]) { }
}
export class FetchOkRuleInstanceProblemTimeAction implements Action {
    public type = '[Monitor] Fetch OK RuleInstance ProblemTime action';
}
export class FetchFailedRuleInstanceProblemTimeAction implements Action {
    public type = '[Monitor] Fetch Cancel RuleInstance ProblemTime action';
    constructor(public readonly error: Error | string | any) { }
}

// #endregion async Fetch RuleInstance ProblemTime


//#endregion async Update RuleInstanceState

export class LoadInStateRuleInstanceLastCheckedDataAction implements Action {
    public type = '[Monitor] Load RuleInstanceLastCheckedData action';

    constructor(public readonly data: RuleInstanceLastCheckedData[]) { }
}

//#region async Fetch RuleInstanceLastCheckedData

export class FetchRuleInstanceLastCheckedDataAction implements Action {
    public type = '[Monitor] Fetch RuleInstanceLastCheckedData action';
    constructor(public readonly sectionId: number, public readonly ruleInstanceIds: number[]) { }
}
export class FetchOkRuleInstanceLastCheckedDataAction implements Action {
    public type = '[Monitor] Fetch OK RuleInstanceLastCheckedData action';
}
export class FetchFailedRuleInstanceLastCheckedDataAction implements Action {
    public type = '[Monitor] Fetch Cancel RuleInstanceLastCheckedData action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch RuleInstanceLastCheckedData

//#region My Rigs

export class LoadInStateMonitorMyRigsAction implements Action {
    public type = '[Monitor] Load in state My Rigs action';
    constructor(public readonly myRigs: number[]) { }
}

export class FetchMonitorMyRigsAction implements Action {
    public type = '[Monitor] Fetch My Rigs action';
}

export class FetchOkMonitorMyRigsAction implements Action {
    public type = '[Monitor] Fetch OK My Rigs action';
}

export class FetchFailedMonitorMyRigsAction implements Action {
    public type = '[Monitor] Fetch Fail My Rigs action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion My Rigs

export class LoadSettingsStateAction implements Action {
    public type = '[Monitor] Load Settings action';

    constructor(public readonly settings: MonitorSectionSettings) { }
}

export class FetchHDepDecimatedLogAction implements Action {
    public type = '[Monitor] Fetch hDep decimated log action';

    constructor(public readonly sectionId: number,
        public readonly periodInSeconds: number,
        public readonly maxPoints: number,
        public readonly timeZone: string) { }
}

export class FetchHDepDecimatedLogOkAction implements Action {
    public type = '[Monitor] Fetch hDep decimated log OK action';
}

export class FetchHDepDecimatedLogFailedAction implements Action {
    public type = '[Monitor] Fetch hDep decimated log Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadHDepDecimatedLogInStateAction implements Action {
    public type = '[Monitor] Load hDep decimated log in state action';

    constructor(public readonly log: DecimatedLog) { }
}

export class FetchBDepDecimatedLogAction implements Action {
    public type = '[Monitor] Fetch bDep decimated log action';

    constructor(public readonly sectionId: number,
        public readonly periodInSeconds: number,
        public readonly maxPoints: number,
        public readonly timeZone: string) { }
}

export class FetchBDepDecimatedLogOkAction implements Action {
    public type = '[Monitor] Fetch bDep decimated log OK action';
}

export class FetchBDepDecimatedLogFailedAction implements Action {
    public type = '[Monitor] Fetch bDep decimated log Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadBDepDecimatedLogInStateAction implements Action {
    public type = '[Monitor] Load bDep decimated log in state action';

    constructor(public readonly log: DecimatedLog) { }
}

// #region MatchingIssues

export class LoadInStateMatchingIssuesAction implements Action {
    public type = '[Monitor] Load MatchingIssues action';
    constructor(public readonly matchingIssuesResult: MatchingIssuesResult[]) { }
}

export class ClearInStateMatchingIssuesAction implements Action {
    public type = '[Monitor] Clear Matching Issues action';
}

export class FetchMatchingIssuesAction implements Action {
    public type = '[Monitor] Fetch MatchingIssues action';
    constructor(public readonly filter: MatchingIssuesFilter) { }
}
export class FetchOkMatchingIssuesAction implements Action {
    public type = '[Monitor] Fetch OK MatchingIssues action';
}
export class FetchFailedMatchingIssuesAction implements Action {
    public type = '[Monitor] Fetch Cancel MatchingIssues action';
    constructor(public readonly error: Error | string | any) { }
}

// #endregion MatchingIssues

// #region Rule suppression
export class SetRuleSuppressionAction implements Action {
    public type = '[Monitor] Set Rule Suppression action';
    constructor(public readonly ruleInstanceId: number, public readonly info: RuleSuppressionInfo, public readonly sectionId: number) { }
}
export class SetRuleSuppressionOkAction implements Action {
    public type = '[Monitor] Set Rule Suppression action OK';
}
export class SetRuleSuppressionFailedAction implements Action {
    public type = '[Monitor] Set Rule Suppression action Failed';
    constructor(public readonly error: Error | string | any) { }
}
// #endregion Rule suppression

// #region ProblemTime suppression
export class SetProblemTimeSuppressionAction implements Action {
    public type = '[Monitor] Set Problem Time Suppression action';
    constructor(public readonly ruleInstanceId: number, public readonly info: RuleSuppressionInfo,
        public readonly sectionId: number, public readonly ruleInstanceIdsToReload: number[]) { }
}
export class SetProblemTimeSuppressionOkAction implements Action {
    public type = '[Monitor] Set Rule Problem Time Suppression action OK';
}
export class SetProblemTimeSuppressionFailedAction implements Action {
    public type = '[Monitor] Set Rule Problem Time Suppression action Failed';
    constructor(public readonly error: Error | string | any) { }
}
// #endregion ProblemTime suppression

export class RequestDataRefreshAction implements Action {
    public type = '[Monitor] Request data refresh action';
    constructor(public readonly timestamp: Date) { }
}

// #region plottable logs
export class GetPlottableLogsAction implements Action {
    public type = '[Monitor] Get Plottable Logs action';
    constructor(public readonly sectionId: number) { }
}
export class GetPlottableLogsOkAction implements Action {
    public type = '[Monitor] Get Plottable Logs action OK';
}
export class GetPlottableLogsFailedAction implements Action {
    public type = '[Monitor] Get Plottable Logs action Failed';
    constructor(public readonly error: Error | string | any) { }
}
export class SavePlottableLogsInStateAction implements Action {
    public type = '[Monitor] Save Plottable Logs In State action';
    constructor(public readonly plottableLogs: IndexedLogMetadata[]) { }
}
// #endregion plottable logs

// #region additional curves
export class GetAdditionalDecimatedCurveAction implements Action {
    public type = '[Monitor] Get additional decimated curve action';

    constructor(public readonly sectionId: number,
        public readonly tag: string,
        public readonly periodInSeconds: number,
        public readonly maxPoints: number,
        public readonly timeZone: string) { }
}

export class GetAdditionalDecimatedCurveOkAction implements Action {
    public type = '[Monitor] Get additional decimated curve OK action';
}

export class GetAdditionalDecimatedCurveFailedAction implements Action {
    public type = '[Monitor] Get additional decimated curve Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class SaveAdditionalDecimatedCurveInStateAction implements Action {
    public type = '[Monitor] Save Additional Decimated Curve In State action';

    constructor(public readonly log: DecimatedLog) { }
}

export class GetAdditionalDecimatedCurvesAction implements Action {
    public type = '[Monitor] Get additional decimated curves action';

    constructor(public readonly sectionId: number,
        public readonly tags: string[],
        public readonly periodInSeconds: number,
        public readonly maxPoints: number,
        public readonly timeZone: string) { }
}

export class GetAdditionalDecimatedCurvesOkAction implements Action {
    public type = '[Monitor] Get additional decimated curves OK action';
}

export class GetAdditionalDecimatedCurvesFailedAction implements Action {
    public type = '[Monitor] Get additional decimated curves Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class SaveAdditionalDecimatedCurvesInStateAction implements Action {
    public type = '[Monitor] Save Additional Decimated Curves In State action';

    constructor(public readonly logs: DecimatedLog[]) { }
}
// #endregion additional curves

export class ClearMonitorStateAction implements Action {
    public type = '[Monitor] Clear all monitor data';
}

// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { SimpleConnection } from '../connection/index';
import { RigState } from '../rig-state';

export class SectionMonitorInfo {
    public orderId: number;
    public sectionId: number;
    /** the Section Name of the current Section */
    public sectionName: string;
    public activeDepthRangeName: string;
    /** Targets configured in the Order */
    public targets: SimpleConnection[];

    public rigId: number;
    public rigInstallationName: string;
    /** Information about the Rig */
    public holeDepth: number;
    public holeDepthUom: string;
    public holeDepthTimeIndex: Date;
    public bitDepth: number;
    public bitDepthUom: string;
    public bitDepthTimeIndex: Date;
    /** The Rig Time offset should be calculated from the timezone */
    public rigLocalTime: Date;
    public rigTimeZone: string;
    public rigState: RigState;
    public rigStateTimeIndex: Date;

    public wellId: string;
    public wellName: string;
    /** wellbore field */
    public wellboreName: string;
    /** Field, MWD provider and ML provider */
    public field: string;
    public mwd: string;
    public ml: string;
    public cement: string;
    public trajectory: string;
    /** Start Date and End Date are the planned Start and End Date */
    public sectionStart?: Date;
    public sectionEnd?: Date;
    public activatedDate?: Date;
}

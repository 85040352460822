//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { mapMany } from '@/_helpers/array/map-many';
import { PickListType } from '@/_models/pick-list';
import { PickListItem } from '@/_models/pick-list-item';
import { DapPickerType } from '@/_models/dap-common';
import { DapDocumentContentField, DapDocumentContentFieldValue, DapTemplateContentPicker } from '@/_models/dap-document-details';
import { DapCatalogService } from '@/_services/dap-catalog.service';
import { resolvePickListType } from '@/_models/pick-list';
import { DapCurveHelper } from '@/dap/document-details/helpers';

@Injectable()
export class DapPickerSelector {
    private picklistMap: Map<PickListType, PickListItem[]>;
    private allPickListItems: PickListItem[];

    constructor(private readonly dapCatalogService: DapCatalogService) {
    }

    public getObservablePickListItems(
        picker: DapTemplateContentPicker,
        contextMap: Map<string, any>): Observable<DapDocumentContentFieldValue[]> {
        const pickerListType = this.getDapPickerListType(picker);
        const picklist = picker.picklist;
        const context = picker.context;

        switch (pickerListType) {
            case DapPickerType.Standard:
                return this.getDefaultObservablePickListItems(picklist, context, contextMap);
            case DapPickerType.ServiceCatalog:
                return this.getServiceProviderObservablePickListItems(context, contextMap);
            case DapPickerType.ToolCatalog:
                return this.getToolProviderObservablePickListItems(context, contextMap);
            case DapPickerType.ToolGroupCatalog:
                return this.getToolGroups(context, contextMap);
            case DapPickerType.CurveCatalog:
                return this.getCurves(context, contextMap);
            default:
                return of([]);
        }
    }

    public setPickerListMap(picklistMap: Map<PickListType, PickListItem[]>) {
        this.picklistMap = picklistMap;
        this.allPickListItems = mapMany([...this.picklistMap.values()], x => x);
    }

    private getDapPickerListType(picker: DapTemplateContentPicker): DapPickerType {
        if (picker?.picklist === null || picker.picklist.length <= 0) {
            throw new Error('Unable to determine picker type, picklist is not specfied.');
        }

        const pickerString = picker.picklist;
        if (!pickerString.includes(':')) {
            return DapPickerType.Standard;
        }

        const pickerCategory = pickerString.split(':')[0].toLowerCase();
        if (pickerCategory !== 'catalog') {
            // Picker category not recofnized, fallback to Standard picker
            return DapPickerType.Standard;
        } else {
            const catalog = pickerString.split(':')[1].toLowerCase();
            switch (catalog) {
                case 'service':
                    return DapPickerType.ServiceCatalog;
                case 'tool':
                    return DapPickerType.ToolCatalog;
                case 'toolgroup':
                    return DapPickerType.ToolGroupCatalog;
                case 'curve':
                    return DapPickerType.CurveCatalog;
                default:
                    throw new Error('Catalog type not recognized');
            }
        }
    }

    private getDefaultObservablePickListItems(picklistName: string, contexts: string[],
        contextMap: Map<string, string>): Observable<DapDocumentContentFieldValue[]> {
        const picklistType: PickListType = resolvePickListType(picklistName);

        let parentValue: string = null;
        if (contexts && contexts.length > 0) {
            const contextMapKey = contexts.join(';');
            if (contextMap && contextMap.has(contextMapKey) &&
                contextMap.get(contextMapKey) && contextMap.get(contextMapKey).length > 0) {
                parentValue = contextMap.get(contextMapKey);
            }
        }

        const items = this.picklistMap && this.picklistMap.get(picklistType);
        const filteredItems = items?.filter(item => {
            if (item.scopingPicklistItemId === null) {
                return true;
            } else if (parentValue === null) {
                return false;
            } else {
                const parentItem = this.allPickListItems.find(x => x.id === item.scopingPicklistItemId && x.name === parentValue);
                return parentItem ? parentItem.id === item.scopingPicklistItemId : false;
            }
        });

        return of(filteredItems &&
            filteredItems?.map(x => {
                    const fieldValue = new DapDocumentContentFieldValue();
                    fieldValue.str = x.name;
                    fieldValue.props = [];
                    fieldValue.props.push({key: 'id', int: x.id} as DapDocumentContentField);
                    return fieldValue;
                }));
    }

    private getServiceProviderObservablePickListItems(contexts: string[],
        contextMap: Map<string, string>): Observable<DapDocumentContentFieldValue[]> {
        if (!contexts || contexts.length <= 0) {
            throw Error('ServiceCatalog picker must have a single context to supply the service provider.');
        }

        if (contexts.length !== 1 || contexts[0].length <= 0) {
            throw Error('ServiceCatalog picker must have a single context to supply the service provider.');
        }

        const serviceProviderContext = contexts[0].trim();
        if (!contextMap || !contextMap.has(serviceProviderContext)) {
            throw Error('ServiceCatalog picker context must be registered in dapContextMap.');
        }

        const serviceProviderName = contextMap.get(serviceProviderContext);
        if (!serviceProviderName) {
            return of([]);
        }

        const serviceProviderItems = this.picklistMap && this.picklistMap.get(PickListType.ServiceProvider) || [];
        if (!serviceProviderItems || serviceProviderItems.length <= 0) {
            return of([]);
        }

        const serviceProviderId = serviceProviderItems.find(x => x.name.toLowerCase() === serviceProviderName.toLocaleLowerCase()).id;
        if (!serviceProviderId) {
            return of([]);
        }

        // TODO: Needs an update should only be called during selection NOT on initialization?
        return this.dapCatalogService.getServiceIds(serviceProviderId).pipe(map(pairs =>
            pairs.map(pair => {
                const fieldValue = new DapDocumentContentFieldValue();
                fieldValue.str = pair.name;
                return fieldValue;
            })
        ));
    }

    private getToolProviderObservablePickListItems(contexts: string[],
        contextMap: Map<string, any>): Observable<DapDocumentContentFieldValue[]> {
        if (contexts === null || contexts.length < 2 || contexts.length > 3) {
            throw Error('ToolCatalog picker must have two or three contexts to supply the service provider, service and optionally a list of tools.');
        }

        if (contexts.length === 3) {
            const toolsContext = contexts[2].trim();
            if (!contextMap || !contextMap.has(toolsContext)) {
                return of([]);
            }
    
            const tools = contextMap.get(toolsContext);
            return of(tools?.map(x => {
                        const fieldValue = new DapDocumentContentFieldValue();
                        fieldValue.str = x;
                        return fieldValue;
                    }));
        }

        const serviceProviderContext = contexts[0].trim();
        const serviceContext = contexts[1].trim();

        if (!contextMap || !contextMap.has(serviceProviderContext) ||
            !contextMap.has(serviceContext)) {
            return of([]);
        }

        const serviceProviderName = contextMap.get(serviceProviderContext);
        const serviceName: string = contextMap.get(serviceContext);
        if (!serviceProviderName || !serviceName) {
            return of([]);
        }

        const serviceProviderItems = this.picklistMap && this.picklistMap.get(PickListType.ServiceProvider) || [];
        if (!serviceProviderItems || serviceProviderItems.length <= 0) {
            return of([]);
        }

        const serviceItems = this.picklistMap && this.picklistMap.get(PickListType.Service) || [];
        if (!serviceItems || serviceItems.length <= 0) {
            return of([]);
        }

        const serviceProviderId = serviceProviderItems.find(x => x.name.toLowerCase() === serviceProviderName.toLocaleLowerCase())?.id;
        const serviceId = serviceItems.find(x => x.name.toLowerCase().startsWith(serviceName.toLowerCase()))?.id;
        if (!serviceProviderId || !serviceId) {
            return of([]);
        }

        // TODO: Needs an update should only be called during selection NOT on initialization?
        return this.dapCatalogService.getTools(serviceProviderId, serviceId).pipe(map(tools =>
            tools.map(tool => {
                const fieldValue = new DapDocumentContentFieldValue();
                fieldValue.str = tool.name;
                fieldValue.props = [];
                fieldValue.props.push({key: 'service-id', int: serviceId} as DapDocumentContentField);
                fieldValue.props.push({key: 'service-provider-id', int: serviceProviderId} as DapDocumentContentField);
                fieldValue.props.push({key: 'tool-group', str: tool.groupName} as DapDocumentContentField);
                return fieldValue;
            })
        ));
    }

    private getToolGroups(contexts: string[],
        contextMap: Map<string, string>): Observable<DapDocumentContentFieldValue[]> {
        if (contexts === null || contexts.length <= 0) {
            throw Error('ToolGroupCatalog picker must have two contexts to supply the service provider and service.');
        }

        if (contexts.length !== 2 || contexts[0].length <= 0 || contexts[1].length <= 0) {
            throw Error('ToolGroupCatalog picker must have two contexts to supply the service provider and service.');
        }

        const serviceProviderContext = contexts[0].trim();
        const serviceContext = contexts[1].trim();

        if (!contextMap || !contextMap.has(serviceProviderContext) || !contextMap.has(serviceContext)) {
            return of([]);
        }

        const serviceProviderName = contextMap.get(serviceProviderContext);
        const serviceName: string = contextMap.get(serviceContext);
        if (!serviceProviderName || !serviceName) {
            return of([]);
        }

        const serviceProviderItems = this.picklistMap && this.picklistMap.get(PickListType.ServiceProvider) || [];
        if (!serviceProviderItems || serviceProviderItems.length <= 0) {
            return of([]);
        }

        const serviceItems = this.picklistMap && this.picklistMap.get(PickListType.Service) || [];
        if (!serviceItems || serviceItems.length <= 0) {
            return of([]);
        }

        const serviceProviderId = serviceProviderItems.find(x => x.name.toLowerCase() === serviceProviderName.toLocaleLowerCase())?.id;
        const serviceId = serviceItems.find(x => x.name.toLowerCase() === serviceName.toLowerCase())?.id;
        if (!serviceProviderId || !serviceId) {
            return of([]);
        }

        // TODO: Needs an update should only be called during selection NOT on initialization?
        return this.dapCatalogService.getToolGroups(serviceProviderId, serviceId).pipe(map(pairs =>
            pairs.map(pair => {
                const fieldValue = new DapDocumentContentFieldValue();
                fieldValue.str = pair.name;
                return fieldValue;
            })
        ));
    }

    private getCurves(contexts: string[],
        contextMap: Map<string, string>): Observable<DapDocumentContentFieldValue[]> {

        if (contexts === null || contexts.length !== 3 || contexts[0].length <= 0 || contexts[1].length <= 0 || contexts[2].length <= 0) {
            throw Error('The Curve picker context must specify service provider, service abd tool.');
        }

        const serviceProviderContext = contexts[0].trim();
        const serviceContext = contexts[1].trim();
        const toolContext = contexts[2].trim();

        if (!contextMap || !contextMap.has(serviceProviderContext) || !contextMap.has(serviceContext) ||  !contextMap.has(toolContext)) {
            return of([]);
        }

        const serviceProviderName = contextMap.get(serviceProviderContext);
        const serviceName = contextMap.get(serviceContext);
        const tool = contextMap.get(toolContext);
        if (!serviceProviderName || !serviceName) {
            return of([]);
        }

        const serviceProviderItems = this.picklistMap && this.picklistMap.get(PickListType.ServiceProvider) || [];
        if (!serviceProviderItems || serviceProviderItems.length <= 0) {
            return of([]);
        }

        const serviceItems = this.picklistMap && this.picklistMap.get(PickListType.Service) || [];
        if (!serviceItems || serviceItems.length <= 0) {
            return of([]);
        }

        const serviceProviderId = serviceProviderItems.find(x => x.name.toLowerCase() === serviceProviderName.toLocaleLowerCase())?.id;
        const serviceId = serviceItems.find(x => x.name.toLowerCase() === serviceName.toLowerCase())?.id;
        if (!serviceProviderId || !serviceId || !tool) {
            return of([]);
        }

        // TODO: Needs an update should only be called during selection NOT on initialization?
       return this.dapCatalogService.getCurves(serviceProviderId, serviceId, tool).pipe(map(curves =>
            curves.map(curve => DapCurveHelper.mapDapCurveToFieldValue(curve))
        ));
    }
}

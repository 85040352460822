// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { OrderStatus } from './order-status';

export class OrderSimple {
    public id: number;
    public name: string;
    public rigOrInstallationId?: number;
    public rigName?: string;
    public wellName?: string;
    public wellId?: string;
    public wellboreName?: string;
    public wellboreId?: string;
    public orderStartDate?: Date;
    public orderStatus?: OrderStatus;
    public completedSectionCount?: number;
    public totalSectionCount?: number;
    /** The name of the currently active or the next planned section. */
    public sectionName?: string;
    /** The id of the currently active or the next planned section. */
    public sectionId?: number;
    public isSectionActive?: boolean;
    public isTemplate?: boolean;
}

// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { ApplianceJobVM, ApplianceServiceVM } from "@/_models/appliance-common";
import { MerlinAuthSettingsConfigVm, MerlinReleaseInfoVM, MerlinRepoStatisticsVm, MerlinRoutingTableItemVm, MerlinUpdateAuthSettingsConfigVm } from "@/_models/merlin-management";
import { MerlinApplianceVm } from "@/_models/merlin-management/merlin-appliance-vm";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class MerlinManagementService {
    
    constructor(private http: HttpClient) { }

    getAll(): Observable<MerlinApplianceVm[]> {
        return this.http.get<MerlinApplianceVm[]>(`${environment.portalWebApi}/MerlinManagement`);
    }

    getJobs(applianceId: number, instanceId: number): Observable<ApplianceJobVM[]> {
        return this.http.get<ApplianceJobVM[]>(`${environment.portalWebApi}/MerlinManagement/${applianceId}/${instanceId}/jobs`).pipe(
            tap(x => x && x.filter(item => item.lastUpdateTime).forEach(item => item.lastUpdateTime = new Date(item.lastUpdateTime)))
        );
    }

    getServices(applianceId: number): Observable<ApplianceServiceVM[]> {
        return this.http.get<ApplianceServiceVM[]>(`${environment.portalWebApi}/MerlinManagement/${applianceId}/services`).pipe(
            tap(x => x && x.filter(item => item.lastUpdate).forEach(item => item.lastUpdate = new Date(item.lastUpdate)))
        );
    }

    getRoutingTable(applianceId: number): Observable<MerlinRoutingTableItemVm[]> {
        return this.http.get<MerlinRoutingTableItemVm[]>(`${environment.portalWebApi}/MerlinManagement/${applianceId}/routingtable`).pipe(
            tap(x => x && x.forEach(item => {
                item.firstRequest = item.firstRequest ? new Date(item.firstRequest) : null;
                item.lastRequest = item.lastRequest ? new Date(item.lastRequest): null;
            }))
        );
    }
    
    getAuthSettings(applianceId: number, poolName: string): Observable<MerlinAuthSettingsConfigVm> {
        return this.http.get<MerlinAuthSettingsConfigVm>(`${environment.portalWebApi}/MerlinManagement/${applianceId}/${poolName}/authsettings`);
    }

    updateAuthSettings(applianceId: number, poolName: string, updateAuthSettingsModel: MerlinUpdateAuthSettingsConfigVm): Observable<void> {
        return this.http.post<void>(`${environment.portalWebApi}/MerlinManagement/${applianceId}/${poolName}/authsettings`,
            updateAuthSettingsModel
        );
    }

    getRepoStatistics(applianceId: number, instanceId: number): Observable<MerlinRepoStatisticsVm[]> {
        return this.http.get<MerlinRepoStatisticsVm[]>(`${environment.portalWebApi}/MerlinManagement/${applianceId}/${instanceId}/repostatistics`).pipe(
            tap(x => x && x.forEach(item => {
                item.created = item.created ? new Date(item.created) : null;
                item.lifetime = item.lifetime ? new Date(item.lifetime): null;
                item.disposed = item.disposed ? new Date(item.disposed): null;
            }))
        );
    }
    
    runServiceCommand(applianceId: number, serviceId: string, commandName: string): Observable<ApplianceServiceVM> {
        return this.http.get<ApplianceServiceVM>(`${environment.portalWebApi}/MerlinManagement/${applianceId}/services/${serviceId}/${commandName}`);
    }

    getMerlinReleaseInfo(): Observable<MerlinReleaseInfoVM> {
        return this.http.get<MerlinReleaseInfoVM>(`${environment.portalWebApi}/MerlinRelease`);
    }
    
    approveMerlinRelease(id: number): Observable<MerlinReleaseInfoVM> {
        return this.http.put<MerlinReleaseInfoVM>(`${environment.portalWebApi}/MerlinRelease/${id}`, null);
    }
}
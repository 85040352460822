// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable, Injector } from '@angular/core';
import { PublicPart } from 'ngx-shared';
import { ConfigurationService } from './configuration.service';
import { Routes } from '@/configuration/routes';
import { environment } from 'environments/environment';
import { MsalService, MsalBroadcastService, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BrowserCacheLocation, EventMessage, EventType, IPublicClientApplication,
  InteractionStatus,
  InteractionType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

export function MSALInstanceFactory(configService: ConfigurationService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: configService.getSettings('openIdConnect').client_id,
      authority: configService.getSettings('openIdConnect').authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/' + Routes.postLogout,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', 'openid', 'profile']
    },
    loginFailedRoute: '/' + Routes.loginFailed
  };
}
export function MSALInterceptorConfigFactory(configService: ConfigurationService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(`${environment.portalWebApi}/SystemConfig/public`, null);
  protectedResourceMap.set(`${environment.portalWebApi}/deployment`, null);
  protectedResourceMap.set(`${environment.portalWebApi}`, [configService.getSettings('openIdConnect').api.scope]);
  protectedResourceMap.set(`${environment.kpiExportApi}`, [configService.getSettings('openIdConnect').api.scope]);
  protectedResourceMap.set(`${environment.statusAggregatorApi}`, [configService.getSettings('openIdConnect').api.scope]);
  protectedResourceMap.set(`${environment.ruleEngineApi}`, [configService.getSettings('openIdConnect').api.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    public isAuthenticated: boolean;

    private subscription: Subscription;  
    private get msalGuardConfig() { return this._injector.get<MsalGuardConfiguration>(MSAL_GUARD_CONFIG); }  
    private get authService() { return this._injector.get(MsalService); }
    private get msalBroadcastService() { return this._injector.get(MsalBroadcastService); }
    private get router() { return this._injector.get(Router); }

    constructor(private _injector: Injector) {
        this.isAuthenticated = false;
    }

    public completeAuthentication() {      
      this.setAuthenticated();
  
      this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe(() => {
          if (this.authService.instance.getAllAccounts().length === 0) {
            this.router.navigate([Routes.default])
          } else {
            this.setAuthenticated();
          }
        });
      
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),         
        )
        .subscribe(() => {
          this.setAuthenticated();
          this.checkAndSetActiveAccount();
        });          
    }

    private setAuthenticated() {
      this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
    }

    private checkAndSetActiveAccount(){
      const activeAccount = this.authService.instance.getActiveAccount();
  
      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        const accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);
      }
    }

    public login() : Observable<void> {
      return this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    }

    public logout() : Observable<void> {
      return this.authService.logoutRedirect();
    }

    public unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
}

/* eslint-disable */
export class FakeAuthenticationService implements PublicPart<AuthenticationService> {

    public completeAuthentication() {
        throw new Error('Method not implemented.');
    }

    public login() : Observable<void> {
        throw new Error('Method not implemented.');
    }

    public logout() : Observable<void> {
        throw new Error('Method not implemented.');
    }

    public unsubscribe() { }

    public get isAuthenticated(): boolean {
        throw new Error('Method not implemented.');
    }
}

export const fakeAuthenticationServiceProvider = { provide: AuthenticationService, useClass: FakeAuthenticationService };
/* eslint-enable */

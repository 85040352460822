// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Store, Action } from '../ngrx-actions';
import {
    LoadInStatePickListsAction, LoadInStatePickListItemsAction, CreateInStatePickListItemAction,
    UpdateInStatePickListItemAction, DeleteInStatePickListItemAction, UpdateInStatePickListItemsOrderAction
} from './pick-list.actions';
import { PickListState } from './pick-list.state';

@Store({} as PickListState)
export class PickListStore {

    @Action(LoadInStatePickListsAction)
    public loadPickLists(state: PickListState, { pickLists }: LoadInStatePickListsAction) {
        return { ...state, pickLists: pickLists } as PickListState;
    }

    @Action(LoadInStatePickListItemsAction)
    public loadPickListItems(state: PickListState, { pickListItems }: LoadInStatePickListItemsAction) {
        return { ...state, pickListItems: pickListItems } as PickListState;
    }

    @Action(CreateInStatePickListItemAction)
    public createPickListItem(state: PickListState, { pickListItem }: CreateInStatePickListItemAction) {
        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }
        if (!pickListItem) { // extract to contract check
            throw new Error('PickListItem is not defined.');
        }

        const newPickLists = [...state.pickListItems];
        newPickLists.push(pickListItem);
        return { ...state, pickListItems: newPickLists } as PickListState;
    }

    @Action(UpdateInStatePickListItemAction)
    public updatePickListItem(state: PickListState, { pickListItem }: UpdateInStatePickListItemAction) {
        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }
        if (!pickListItem) { // extract to contract check
            throw new Error('PickListItem is not defined.');
        }

        const index = state.pickListItems.findIndex(x => x.id === pickListItem.id);

        if (index < 0) {
            throw new Error(`PickListItem (id: ${pickListItem.id}) not found: `);
        }

        const newPickLists = [...state.pickListItems];
        newPickLists[index] = { ...pickListItem };
        return { ...state, pickListItems: newPickLists } as PickListState;
    }

    @Action(DeleteInStatePickListItemAction)
    public deletePickListItem(state: PickListState, { pickListItemId }: DeleteInStatePickListItemAction) {
        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }

        if (state.pickListItems.every(x => x.id !== pickListItemId)) {
            throw new Error(`PickListItem with id "${pickListItemId}" does not exist in the state.`);
        }

        const newPickLists = state.pickListItems.filter(x => x.id !== pickListItemId);
        return { ...state, pickListItems: newPickLists } as PickListState;
    }

    @Action(UpdateInStatePickListItemsOrderAction)
    public updatePickListItemsOrder(state: PickListState, { pickListItems }: UpdateInStatePickListItemsOrderAction) {

        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }

        const itemsInState = [...state.pickListItems];
        pickListItems.forEach(item => {
            const itemIndex = itemsInState.findIndex(i => i.id === item.id);
            if (itemIndex > -1) {
                itemsInState[itemIndex] = item;
            } else {
                itemsInState.push(item);
            }
        });

        return { ...state, pickListItems: itemsInState } as PickListState;
    }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // this will be proxied:
  // 1) local development (look at dev-proxy.json)
  // 2) production with docker (look at nginx.conf)
  portalWebApi: '/api',
  // don't make this just '/dap/api' because the proxy will then rewrite everything with '/dap'
  dapApi: '/dap-service/api',
  statusAggregatorApi: '/status-aggregator/api',
  ruleEngineApi: '/rule-engine/api',
  kpiExportApi: '/kpi-exporter/api',
  liveKpiApi: '/live-kpi/api',
  wikiURL: 'https://wiki.drq.pds.software',
  wikiReleaseNotes: '/releases',
  wikiUserGuide: '/userGuide',
  wikiHelpDesk: '/contactHelpDesk',
  version: '2019.0.0.0-dev',
};

// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export enum TaggedCurvePlotMode {
    Hidden,
    OwnAxis,
    SharedAxis
}

export class TaggedCurvePlot {
    public tag: string;
    public axes: TaggedCurvePlotMode;
    public inverted = false;
    public from0 = false;
  }
  
export class MonitorSectionSettings {
    public timeZone: string;
    public showDepth: boolean;
    public depthFrom0: false;
    public additionalCurvesEnabled = false;
    public additionalCurves: TaggedCurvePlot[];
}
